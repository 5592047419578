import { useState } from "react";
import Sidebar from "./Sidebar";
import { Button } from "@material-ui/core";

const SidebarWrapper = ({ isAuthenticated }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      {isAuthenticated && (
        <>
          <button className="burger-menu" onClick={toggleSidebar}>
            &#9776; {/* Burger menu icon */}
          </button>
          <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
        </>
      )}
    </>
  );
};

export default SidebarWrapper;
