import { useNavigate } from "react-router-dom"; // Assuming you are using react-router for navigation
import store, { setUserName, setIsAllowedGuests } from "../redux/maxStore";
import "./landingPage.scss"; // Import CSS for the design
import { useEffect, useState } from "react";
import api from "../api";

const LandingPage = (props: any) => {
  const navigate = useNavigate(); // useNavigate hook to handle navigation

  const [isGuestsEnabled, setIsGuestsEnabled] = useState(false);
  // Function to handle guest login
  const handleGuest = (event: any) => {
    try {
      event.preventDefault();
      localStorage.setItem("token", "guest_token");
      store.dispatch(setUserName("guest")); // Dispatch Redux action to set guest user
      props.setLanded(true);
      navigate("/projects"); // Navigate to the projects page
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

  // Function to redirect to login page
  const handleLogin = () => {
    props.setLanded(true);
    navigate("/login"); // Redirect to login page
  };

  //subscribe  to the redux  store

  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState();
      setIsAllowedGuests(state.isAllowGuests);
    });
  }, []);

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const settings = await api.get("settings/");
    setIsAllowedGuests(!!settings.data.allowGuests);
    return settings;
  };
  return (
    <div className="landing-container">
      <div className="robot-eyes-animation">
        <div className="eye"></div>
        <div className="eye"></div>
      </div>
      <div className="welcome-message">
        <h1 className="welcome-text">Hi, I am your personal assistant</h1>
        <p className="subtext">How can I help you?</p>

        {/* explain it is possiable to see my protfolio */}
        <small>Click continue to see my portfolio</small>
      </div>
      <div className="action-buttons">
        {/* Login button that redirects to the login page */}
        <button className="action-button login-btn" onClick={handleLogin}>
          Login
        </button>

        {/* Continue as guest button 
        

        
        */}

        {isGuestsEnabled && (
          <button className="action-button continue-btn" onClick={handleGuest}>
            Continue as Guest
          </button>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
