import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";

interface IProps {
  list: { id: number; element: React.ReactElement }[]; // The list should have a unique id for each item
}

interface BoxState {
  id: number;
  x: number;
  y: number;
  item: React.ReactElement;
}

const Grid = ({ list }: IProps) => {
  const [boxes, setBoxes] = useState<BoxState[]>([]);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  // Load positions from localStorage or set defaults
  useEffect(() => {
    const listOfBoxes = list.map((boxItem) => {
      const storedPosition = JSON.parse(
        localStorage.getItem(`box_${boxItem.id}`) || "{}"
      );
      return {
        id: boxItem.id,
        x: storedPosition.x || 0,
        y: storedPosition.y || 0,
        item: boxItem.element,
      };
    });
    setBoxes(listOfBoxes);
  }, [list]);

  // Detect if the device is mobile or tablet
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)"); // Customize screen size as needed
    setIsMobileOrTablet(mediaQuery.matches);

    const handleResize = () => setIsMobileOrTablet(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const handleDrag = (id: number, newX: number, newY: number) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === id ? { ...box, x: newX, y: newY } : box
      )
    );
    localStorage.setItem(`box_${id}`, JSON.stringify({ x: newX, y: newY }));
  };

  return (
    <div
      className="grid"
      style={{ position: "relative", width: "100%", height: "100vh" }}
    >
      {boxes.map((box) =>
        isMobileOrTablet ? (
          // Render without Draggable on mobile or tablet
          <div className="mobile-note-card" key={box.id}>
            {box.item}
          </div>
        ) : (
          <Draggable
            key={box.id}
            bounds="parent"
            defaultPosition={{ x: box.x, y: box.y }}
            onStop={(e, { x, y }) => handleDrag(box.id, x, y)}
            handle=".handle"
          >
            <div className="handle">{box.item}</div>
          </Draggable>
        )
      )}
    </div>
  );
};

export default Grid;
