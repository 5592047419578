import { Mic, VolumeOff, VolumeUp } from "@mui/icons-material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";
import store, { setMute } from "../redux/maxStore";
import SendIcon from "@mui/icons-material/Send";
import "./Chat.scss";

import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { FormControlLabel, Switch } from "@material-ui/core";
const Chat = (props: { sendMessage: Function }) => {
  const [message, setMessage] = useState("");
  const [channel, setChannel] = useState("main");
  const [isMute, setIsMute] = useState(false);
  const navigate = useNavigate();
  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  // Update the message state whenever the transcript changes
  useEffect(() => {
    setMessage(transcript);
  }, [transcript]);

  const send = () => {
    if (message.length === 0) return;
    switch (message.toLowerCase()) {
      case "tasks":
        navigate("/tasks");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "passwords":
        navigate("/managePasswords");
        break;
      case "projects":
        navigate("/projects");
        break;
      case "account":
        navigate("/account");
        break;
      default:
        props.sendMessage(
          `${channel}:${store.getState().username}: ${message}`
        );
        break;
    }
    setMessage("");
    resetTranscript(); // Clear the transcript after sending
  };

  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState();
      setIsMute(state.isMute);
      setChannel(state.channel || channel);
    });
  }, []);

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  });

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      send();
    }
  };

  const scrollableDivRef: any = useRef(null);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop =
          scrollableDivRef.current.scrollHeight + 10;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div ref={scrollableDivRef} className="chat-wrapper">
      <MessageDisplay />
      <div style={{display:'flex',flexDirection:'column'}}>
        <div className="mute-toggle">
          <Switch
            checked={isMute}
            onChange={() => {
              store.dispatch(setMute(!isMute));
            }}
            icon={<VolumeUp />}
            checkedIcon={<VolumeOff />}
            color="primary"
          />
        </div>
        <div className="input-wrapper">
          <TextField
            type="text"
            autoFocus
            style={{ width: "100%" }}
            placeholder="Message"
            className="message-input"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            autoComplete="false"
            InputProps={{
              className: "message-input",
              autoComplete: "false",
              style: { color: "white" },

              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    className={!listening ? "send-button" : "send-button hover"}
                    onClick={() =>
                      listening
                        ? SpeechRecognition.stopListening()
                        : SpeechRecognition.startListening()
                    }
                  >
                    <Mic color="error" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton className="send-button" onClick={send}>
                    <SendIcon color="error" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>{" "}
      </div>
    </div>
  );
};

function CopyButton({ text }: any) {
  const copyToClipboard = () => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  return (
    <div className="copy-container">
      <button className="copy-button" onClick={copyToClipboard}>
        Copy
      </button>
    </div>
  );
}

function MessageComponent({ message }: { message: any }) {
  const formattedContent = message.content.replace(/\r/g, "<br/>");
  const textRef: any = useRef(null);
  return (
    <>
      <span
        ref={textRef}
        className="message"
        dangerouslySetInnerHTML={{ __html: formattedContent }}
      ></span>
      <CopyButton ref={textRef} text={formattedContent} />
      <span className="timestamp">
        {moment().diff(moment(message.timestamp), "minutes")}
      </span>
    </>
  );
}
const splitTextIntoChunks = (text: string = "", maxLength = 100) => {
  if (!text.length) return;
  const sentences = text.match(/[^.!?]+[.!?]*/g) || []; // Split by sentences
  const chunks: string[] = [];
  let currentChunk = "";

  sentences.forEach((sentence) => {
    if ((currentChunk + sentence).length <= maxLength) {
      currentChunk += sentence;
    } else {
      chunks.push(currentChunk);
      currentChunk = sentence;
    }
  });

  if (currentChunk) chunks.push(currentChunk);
  return chunks;
};

let voices: SpeechSynthesisVoice[] = [];
const speechSynthesisInitialized = new Promise<void>((resolve) => {
  // Ensure voices are loaded
  const populateVoices = () => {
    voices = window.speechSynthesis.getVoices();
    if (voices.length) {
      resolve();
    }
  };

  // Listen for voices changed event
  window.speechSynthesis.onvoiceschanged = populateVoices;
  populateVoices(); // Initial population attempt
});

const speakText = async (text: string, mute: boolean) => {
  if (mute) return; // Stop processing if muted
  console.time("speaking");

  if ("speechSynthesis" in window) {
    await speechSynthesisInitialized; // Wait until voices are loaded
    const chunks = splitTextIntoChunks(text) || [];

    for (const chunk of chunks) {
      if (mute) break; // Stop processing further if muted

      const utterance = new SpeechSynthesisUtterance(chunk);
      utterance.lang = "en-US";
      window.speechSynthesis.speak(utterance);

      // Wait for each chunk to finish before moving to the next one
      await new Promise<void>((resolve) => {
        utterance.onend = () => resolve();
      });
    }
    cancelSpeech();

    console.timeEnd("speaking");
  } else {
    toast("Text-to-Speech not supported in this browser.");
  }
};

const cancelSpeech = () => {
  if ("speechSynthesis" in window) {
    window.speechSynthesis.cancel();
  }
};

const MessageDisplay = () => {
  const [mute, setMute] = useState(false);
  const messageLog = useSelector((state: any) => state.value);
  const username = useSelector((state: any) => state.username);

  useEffect(() => {
    const lastMessage = messageLog[messageLog.length - 1];
    const lastSenderName = lastMessage?.sender?.name;

    if (!mute && lastSenderName !== "guest" && lastSenderName !== username) {
      speakText(lastMessage?.content, mute);
    }
  }, [messageLog, mute, username]);

  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState();
      if (state.isMute) cancelSpeech();
      setMute(state.isMute);
    });
  }, []);
  return (
    <div className="display-chat">
      <div className="message-list">
        {messageLog.map((message: any) => (
          <div key={message.id} className="message-item">
            <span>{message.sender.name}</span>
            <MessageComponent message={message} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chat;
