import { Navigate } from "react-router-dom";

// A protected route component to check authentication
const ProtectedRoute = ({ isAuthenticated, children }: any) => {
  if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
