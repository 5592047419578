import { useEffect, useRef } from "react";
import store, { addMessage } from "../redux/maxStore";
interface Message {
  content: string;
  channel: string;
  sender: {
    name: string;
  };
}
const WebSocketClient = () => {
  const socketRef = useRef<WebSocket | null>(null);

  const connectSocket = () => {
    const protocol = window.location.protocol === "https:" ? "wss" : "ws";

    const endUrl = window.location.protocol === "https:" ? "/" : ":8081/";

    const socket = new WebSocket(
      `${protocol}://${window.location.hostname}${endUrl}`
    );

    socketRef.current = socket;

    socket.onopen = () => console.log("WebSocket connected");

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      store.dispatch(addMessage(message));
    };

    socket.onclose = () => {
      setTimeout(connectSocket, 3000); // Reconnect every 3 seconds
    };

    socket.onerror = (error) => {
      console.log(error);
      socket.close();
    };
  };

  useEffect(() => {
    connectSocket();
    return () => socketRef.current?.close();
  }, []);

  const sendMessage = (message: Message) => {
    const socket = socketRef.current;
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket not open, retrying...");
      connectSocket();
      setTimeout(() => {
        if (socketRef.current?.readyState === WebSocket.OPEN) {
          socketRef.current.send(JSON.stringify(message));
        }
      }, 1000);
    }
  };

  return { sendMessage };
};

export default WebSocketClient;
