import { FormControlLabel, Switch } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import "./settings.scss"; // Import CSS file for styling
import api from "../api";
import { toast } from "react-toastify";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const SettingsPage = () => {
  const [tokenExpirationTime, setTokenExpirationTime] = useState("");
  const [allowGuests, setAllowGuests] = useState(false);
  const [paCommands, setPACommands] = useState(false);
  const [showCommandsOutput, setShowCommandsOutput] = useState(false);
  const [id, setId] = useState("");
  const handleTokenExpirationChange = (e: any) => {
    const value = e.target.value;
    setTokenExpirationTime(value);
  };
  useEffect(() => {
    loadSiteSettings();
  }, []);

  useEffect(() => {
    updateSiteSettings();
  }, [allowGuests, paCommands, showCommandsOutput, tokenExpirationTime]);

  //update site settings in the database
  const updateSiteSettings = async () => {
    try {
      if (!id.length) {
        return;
      }
      const response = await api.post("settings/", {
        _id: id,
        tokenExpiration: tokenExpirationTime,
        allowGuests,
        paCommands,
        showCommandsOutput,
      });
      return response.data;
    } catch (error: any) {
      console.error(error);
    }
  };

  //load site settings from the database
  const loadSiteSettings = async () => {
    try {
      const response = await api.get("settings/");
      const siteSettings = response.data;
      setTokenExpirationTime(siteSettings.tokenExpiration);
      setAllowGuests(siteSettings.allowGuests);
      setPACommands(siteSettings.paCommands);
      setShowCommandsOutput(siteSettings.showCommandsOutput);
      setId(siteSettings._id);
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleToggle = (event: any) => {
    setAllowGuests(event.target.checked); // Update state based on switch
  };

  const handlePACmdsToggle = (event: any) => {
    setPACommands(event.target.checked); // Update state based on switch
  };

  const handleShowCmdsOutputToggle = (event: any) => {
    setShowCommandsOutput(event.target.checked); // Update state based on switch
  };

  return (
    <div className="settings-container">
      <h2 className="main-title">Settings</h2>
      <hr />
      <h3 className="sub-title">site</h3>
      <div className="setting-item Token">
        <label className="setting-item-label">Token Expiration Time:</label>
        <input
          type="text"
          value={tokenExpirationTime}
          onChange={handleTokenExpirationChange}
          placeholder="Enter Token Expiration Time"
        />
      </div>
      <div className="setting-item">
        <FormControlLabel
          labelPlacement="start"
          control={
            <Android12Switch
              onChange={handleToggle} // Handle change to update state
              checked={allowGuests}
            />
          }
          label="Allow Guests to the site:"
        />
      </div>
      <div className="setting-item">
        <FormControlLabel
          labelPlacement="start"
          control={
            <Android12Switch
              checked={paCommands}
              onChange={handlePACmdsToggle}
            />
          }
          label="Personal Assistant Cli Commands :"
        />
      </div>
      <div className="setting-item">
        <FormControlLabel
          labelPlacement="start"
          control={
            <Android12Switch
              onChange={handleShowCmdsOutputToggle}
              checked={showCommandsOutput}
            />
          }
          label="Personal Assistant Cli output:"
        />
      </div>
      <div>
        <h3 className="sub-title">Archived Chats</h3>
        <button>Archive All Chats</button>
        <button>Delete All Chats</button>
      </div>
      <div style={{ paddingBottom: "10px" }}>
        <h3 className="sub-title">Manage Archive</h3>
        <button>Archive All</button>
        <button>Delete All</button>
      </div>
      {/* <hr />
      <h3 className="sub-title">account </h3> */}
    </div>
  );
};

export default SettingsPage;
