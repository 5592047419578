import { Button, Checkbox, IconButton, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import api from "../api";
import Grid from "./Grid";
import BasicDateTimePicker from "./BasicDateTimePicker";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

export enum tabOptions {
  TASKS = "tasks",
  NOTES = "notes",
  REMINDERS = "reminders",
}

export interface Item {
  _id?: string;
  id: number;
  title: string;
  type: string;
  content?: string;
  completed?: boolean;
  dateTime?: string;
  priority?: string;
  subtasks?: { title: string; isCompleted: boolean }[];
}

export interface ItemProps {
  item: Item;
  type: tabOptions;
  onUpdateItem: () => void;
  filterItemBasedOnType: (item: Item) => void;
}

export interface ReminderProps {
  dueTime: Date;
}

// Type Guards for Safe Type Handling
const isTask = (item: Item): boolean => item.type === "task";

// Subtask List Component
const SubtaskList: React.FC<{
  editing: boolean;
  subtasks: Item["subtasks"];
  onChange: (index: number, isCompleted: boolean, title?: string) => void;
  onDelete: (index: number) => void; // Add delete handler
  onAdd: () => void;
}> = ({ subtasks, onChange, onDelete, onAdd, editing }) => (
  <div>
    <h4>Subtasks:</h4>
    <ul>
      {subtasks?.map((subtask, index) => (
        <li key={index} style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={subtask.isCompleted}
            onChange={() => onChange(index, !subtask.isCompleted)}
          />
          <TextField
            value={subtask.title}
            onChange={(e) =>
              onChange(index, subtask.isCompleted, e.target.value)
            }
            style={{ marginRight: "8px" }}
          />

          {editing && (
            <IconButton onClick={() => onDelete(index)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          )}
        </li>
      ))}
    </ul>
    <Button onClick={onAdd}>Add Subtask</Button>
  </div>
);

export interface ReminderProps {
  dueTime: Date;
}

const Reminder: React.FC<ReminderProps> = ({ dueTime }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    expired: false,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const distance = new Date(dueTime).getTime() - now;

      if (distance < 0) {
        return { expired: true, days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds, expired: false };
    };

    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [dueTime]);

  return (
    <div style={styles.container}>
      {timeLeft.expired ? (
        <p style={styles.expiredText}>Expired</p>
      ) : (
        <div style={styles.countdown}>
          <div style={styles.timeBox}>
            <span style={styles.number}>{timeLeft.days}</span>
            <span style={styles.label}>Days</span>
          </div>
          <div style={styles.separator}>:</div>
          <div style={styles.timeBox}>
            <span style={styles.number}>{timeLeft.hours}</span>
            <span style={styles.label}>Hours</span>
          </div>
          <div style={styles.separator}>:</div>
          <div style={styles.timeBox}>
            <span style={styles.number}>{timeLeft.minutes}</span>
            <span style={styles.label}>Minutes</span>
          </div>
          <div style={styles.separator}>:</div>
          <div style={styles.timeBox}>
            <span style={styles.number}>{timeLeft.seconds}</span>
            <span style={styles.label}>Seconds</span>
          </div>
        </div>
      )}
    </div>
  );
};

// Inline styles for the countdown display
const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    width: "fit-content",
  },
  countdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  timeBox: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    padding: "5px",
    borderRadius: "4px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  number: {
    fontSize: "24px",
    fontWeight: "bold" as const,
  },
  label: {
    fontSize: "12px",
    color: "#666",
  },
  separator: {
    fontSize: "24px",
    fontWeight: "bold" as const,
    color: "#333",
  },
  expiredText: {
    fontSize: "18px",
    fontWeight: "bold" as const,
    color: "red",
  },
};

const ItemComponent: React.FC<ItemProps> = ({
  item,
  type,
  onUpdateItem,
  filterItemBasedOnType,
}) => {
  const [editing, setEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState<string>(item.title);
  const [editedContent, setEditedContent] = useState<string>(
    item.content || ""
  );
  const [dueDate, setDueDate] = useState<Date | null>(
    item.dateTime ? new Date(item.dateTime) : null
  );
  const [subtasks, setSubtasks] = useState<Item["subtasks"]>(
    item.subtasks || []
  );

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEditedTitle(e.target.value);

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditedContent(e.target.value);

  const handleDueDateChange = (date: Date | null) => setDueDate(date);

  const handleSubtaskChange = (
    index: number,
    isCompleted: boolean,
    title?: string
  ) => {
    const updatedSubtasks = [...(subtasks || [])];
    if (updatedSubtasks[index]) {
      if (title !== undefined) {
        updatedSubtasks[index].title = title;
      }
      updatedSubtasks[index].isCompleted = isCompleted;
    } else if (title !== undefined) {
      updatedSubtasks.push({ title: title, isCompleted });
    }
    setSubtasks(updatedSubtasks);
  };

  const handleAddSubtask = () => {
    setSubtasks([...(subtasks || []), { title: "", isCompleted: false }]);
  };

  const handleSubmit = async () => {
    setEditing(false);

    const updatedItem: Item = {
      _id: item._id,
      id: item.id,
      type: item.type,
      completed: item.completed,
      title: editedTitle,
      content: editedContent,
      dateTime: dueDate ? dueDate.toISOString() : undefined,
      subtasks: isTask(item) ? subtasks : undefined,
    };

    try {
      await api.post("tasks/item", updatedItem);
      onUpdateItem();
      toast("Item successfully updated!");
    } catch (error) {
      console.error(error);
      toast("Failed to update item.");
    }
  };

  // Handle deleting a subtask
  const handleDeleteSubtask = (index: number) => {
    const updatedSubtasks = subtasks?.filter((_, i) => i !== index);
    setSubtasks(updatedSubtasks);
  };

  const handleDeleteItem = async () => {
    try {
      if (item._id) {
        await api.delete(`tasks/item/${item._id}`);
        onUpdateItem();
        toast("Item successfully deleted!");
      } else {
        filterItemBasedOnType(item);
        onUpdateItem();

        toast("Item successfully deleted!");
      }
    } catch (error) {
      console.error(error);
      toast("Failed to delete item.");
    }
  };

  const renderContent = () => {
    switch (type) {
      case tabOptions.NOTES:
        return (
          <div
            className="note-content handle"
            style={{ cursor: "move", display: "flex" }}
          >
            <h3>{item.title}</h3>
            <p className="item-content">{item.content || ""}</p>
          </div>
        );
      case tabOptions.REMINDERS:
        return (
          <div className="reminder-content">
            <h3>{item.title}</h3>
            <BasicDateTimePicker
              value={dueDate || new Date()}
              onChange={handleDueDateChange}
            />
            <Reminder dueTime={dueDate || new Date()} />
            <p className="item-content">{item.content || ""}</p>
          </div>
        );
      case tabOptions.TASKS:
        return (
          <div className="task-content">
            <h3>{item.title}</h3>
            <p className="item-content">{item.content || ""}</p>
            <SubtaskList
              subtasks={subtasks}
              onChange={handleSubtaskChange}
              onAdd={handleAddSubtask}
              onDelete={handleDeleteSubtask}
              editing={editing}
            />
            {item.completed ? <p>Completed</p> : <p>Not Completed</p>}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`card ${type === tabOptions.NOTES ? "resizable-div" : ""}`}>
      {editing && (
        <div className="item-header">
          <IconButton onClick={handleDeleteItem} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      {editing ? (
        <div className="edit-container">
          <TextField
            label="Title"
            variant="outlined"
            value={editedTitle}
            InputLabelProps={{
              style: { color: "white" }, // This will apply to the label's default state
            }}
            onChange={handleTitleChange}
            fullWidth
          />
          <TextField
            label="Content"
            variant="outlined"
            multiline
            minRows={3}
            InputLabelProps={{
              style: { color: "white" }, // This will apply to the label's default state
            }}
            value={editedContent}
            onChange={handleContentChange}
            fullWidth
          />
          {(type === tabOptions.REMINDERS || type === tabOptions.TASKS) && (
            <BasicDateTimePicker
              value={dueDate || new Date()}
              onChange={handleDueDateChange}
            />
          )}
          {type === tabOptions.TASKS && (
            <SubtaskList
              editing={editing}
              subtasks={subtasks}
              onChange={handleSubtaskChange}
              onAdd={handleAddSubtask}
              onDelete={handleDeleteSubtask}
            />
          )}
          <Button
            className="submit-button"
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      ) : (
        <div className="item-view">
          {renderContent()}
          <Button
            className="submit-button"
            variant="text"
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

const Tasks: React.FC = () => {
  const [tasks, setTasks] = useState<Item[]>([]);
  const [notes, setNotes] = useState<Item[]>([]);
  const [reminders, setReminders] = useState<Item[]>([]);
  const [selected, setSelected] = useState<tabOptions>(tabOptions.TASKS);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const [tasksResponse, notesResponse, remindersResponse] =
        await Promise.all([
          api.get("tasks/tasks"),
          api.get("tasks/notes"),
          api.get("tasks/reminders"),
        ]);
      setTasks(Array.isArray(tasksResponse.data) ? tasksResponse.data : []);
      setNotes(Array.isArray(notesResponse.data) ? notesResponse.data : []);
      setReminders(
        Array.isArray(remindersResponse.data) ? remindersResponse.data : []
      );
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const filterItemBasedOnType = (item: Item) => {
    switch (item.type) {
      case tabOptions.NOTES:
        setNotes(notes.filter((note) => note.id !== item.id));
        break;
      case tabOptions.TASKS:
        setTasks(tasks.filter((task) => task.id !== item.id));
        break;
      case tabOptions.REMINDERS:
        setReminders(reminders.filter((reminder) => reminder.id !== item.id));
        break;
    }
  };

  const handleCreation = async () => {
    let newItem: Item;
    switch (selected) {
      case tabOptions.NOTES:
        newItem = {
          id: notes.length,
          title: "New Note",
          content: "Write Note Here",
          type: "note",
        };
        setNotes([...notes, newItem]);
        break;
      case tabOptions.TASKS:
        newItem = {
          id: tasks.length,
          title: "New Task",
          content: "Task Description",
          completed: false,
          type: "task",
        };
        setTasks([...tasks, newItem]);
        break;
      case tabOptions.REMINDERS:
        newItem = {
          id: reminders.length,
          title: "New Reminder",
          content: "Reminder Content",
          dateTime: new Date().toISOString(),
          type: "reminder",
        };
        setReminders([...reminders, newItem]);
        break;
      default:
        return;
    }
  };

  const RenderList: React.FC = () => {
    switch (selected) {
      case tabOptions.TASKS:
        return (
          <>
            {tasks?.map((task) => (
              <ItemComponent
                key={task.id}
                item={task}
                type={tabOptions.TASKS}
                filterItemBasedOnType={filterItemBasedOnType}
                onUpdateItem={fetchItems}
              />
            ))}
          </>
        );
      case tabOptions.NOTES:
        return (
          <Grid
            list={notes?.map((note) => {
              return {
                id: note.id,
                element: (
                  <ItemComponent
                    key={note.id}
                    item={note}
                    type={tabOptions.NOTES}
                    filterItemBasedOnType={filterItemBasedOnType}
                    onUpdateItem={fetchItems}
                  />
                ),
              };
            })}
          />
        );
      case tabOptions.REMINDERS:
        return (
          <>
            {reminders?.map((reminder) => (
              <ItemComponent
                key={reminder.id}
                item={reminder}
                type={tabOptions.REMINDERS}
                filterItemBasedOnType={filterItemBasedOnType}
                onUpdateItem={fetchItems}
              />
            ))}
          </>
        );
      default:
        return null;
    }
  };

  useHotkeys("t", () => setSelected(tabOptions.TASKS));
  useHotkeys("n", () => setSelected(tabOptions.NOTES));
  useHotkeys("r", () => setSelected(tabOptions.REMINDERS));
  useHotkeys("c", () => handleCreation());

  return (
    <div className="page-container">
      <div className="options">
        {Object.values(tabOptions).map((option) => (
          <p
            key={option}
            onClick={() => setSelected(option)}
            className={`${selected === option ? "selected" : ""}`}
          >
            <span>{option.charAt(0).toUpperCase() + option.slice(1)}</span>
          </p>
        ))}
      </div>
      <div className="list-container">
        <RenderList />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleCreation}
        >
          Create New
        </Button>
      </div>
    </div>
  );
};

export default Tasks;
