import React, { useState, useEffect } from "react";

import api from "../api";
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import "./usersManager.scss";

// User type definition
interface User {
  _id: string;
  email: string;
  username: string;
}

// Custom theme with black background and white text
const theme = createTheme({
  palette: {
    background: {
      paper: "transparent",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  // components: {
  //   MuiTableCell: {
  //     styleOverrides: {
  //       root: {
  //         color: "#FFFFFF", // White text color
  //         borderBottom: "1px solid rgba(255, 255, 255, 0.12)", // White border
  //       },
  //     },
  //   },
  //   MuiTableRow: {
  //     styleOverrides: {
  //       root: {
  //         "&:nth-of-type(odd)": {
  //           backgroundColor: "#333333", // Darker background for odd rows
  //         },
  //       },
  //     },
  //   },
  // },
});
const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [newUser, setNewUser] = useState({
    email: "",
    username: "",
    password: "",
  });

  // Fetch users on component load
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get("/users/");
      setUsers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleEditClick = (user: User) => {
    setCurrentUser(user);
    setShowEditModal(true);
  };

  const handleCreateClick = () => {
    setNewUser({ email: "", username: "", password: "" });
    setShowCreateModal(true);
  };

  const handleEditSave = async () => {
    if (!currentUser) return;

    try {
      await api.put(`/users/${currentUser._id}`, currentUser);
      setShowEditModal(false);
      fetchUsers();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleCreateSave = async () => {
    try {
      await api.post(
        "/auth/signup",
        { user: newUser },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setShowCreateModal(false);
      fetchUsers();
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const handleModalClose = () => {
    setShowEditModal(false);
    setShowCreateModal(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="users-manager-container">
        <h2 className="title">User Management</h2>
        <Button
          className="create-user-button"
          variant="contained"
          onClick={handleCreateClick}
        >
          Create New User
        </Button>

        <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user._id}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditClick(user)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal open={showCreateModal} onClose={handleModalClose}>
          <Box sx={modalStyle} className="create-user-popup">
            <h3>Create User</h3>
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={newUser.email}
              style={{ color: "white" }}
              onChange={(e) =>
                setNewUser((prev) => ({ ...prev, email: e.target.value }))
              }
            />
            <TextField
              label="Username"
              fullWidth
              margin="normal"
              color="primary"
              value={newUser.username}
              onChange={(e) =>
                setNewUser((prev) => ({ ...prev, username: e.target.value }))
              }
            />
            <TextField
              label="Password"
              color="primary"
              fullWidth
              margin="normal"
              type="password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser((prev) => ({ ...prev, password: e.target.value }))
              }
            />
            <Button
              className="submit-button"
              variant="contained"
              onClick={handleCreateSave}
            >
              Create User
            </Button>
          </Box>
        </Modal>
      </div>
    </ThemeProvider>
  );
};
const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rebeccapurple",
  borderRadius: "8px",
  boxShadow: 24,
  color: "white",
  p: 4,
};
export default UserManagement;
