import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  ThemeProvider,
  createTheme,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../api";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const darkTheme = createTheme({
  palette: {
    mode: "dark", // Set the mode to 'dark' for the dark theme
  },
});

const PasswordModal = (props: {
  mode: "generate" | "edit";
  currentService: string;
  accountName?: string;
  initialPassword?: string;
  fetchPasswords: Function;
  open: boolean;
  onClose: () => void;
  onSave: Function;
}) => {
  const [accountName, setAccountName] = useState(props.accountName || "");
  const [password, setPassword] = useState(props.initialPassword || "");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (props.accountName) setAccountName(props.accountName);
    if (props.initialPassword) setPassword(props.initialPassword);

    return () => {
      setAccountName("");
      setPassword("");
    };
  }, [props]);

  const handleSubmit = async () => {
    setLoading(true);
    if (props.mode === "generate") {
      await handleGeneratePassword();
    } else {
      await handleSavePassword();
    }
    setLoading(false);
    props.onClose();
  };

  const handleGeneratePassword = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response: any = await api.post(
        "/passwords/passwords/generate",
        {
          service: props.currentService,
          account: accountName,
        },
        { headers }
      );
      if (response.data.status === 200) {
        toast(response.data.message);
        props.fetchPasswords(props.currentService);
      }
      setPassword(response.data.password);
    } catch (error) {
      console.error("Error generating password:", error);
      toast.error("Failed to generate password.");
    }
  };

  const handleSavePassword = async () => {
    props.onSave(password);
    props.fetchPasswords(props.currentService);
    toast.success("Password saved successfully!");
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Modal open={props.open} onClose={props.onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" color="text.primary">
            {props.mode === "generate"
              ? "Generate New Password"
              : "Edit Password"}
          </Typography>
          <TextField
            label="Account Name"
            fullWidth
            margin="normal"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            disabled={props.mode === "edit"} // Disable in edit mode
            sx={{
              "& .MuiInputLabel-root": {
                color: "text.primary", // Ensure label is visible
              },
              "& .MuiInputBase-root": {
                color: "text.primary", // Ensure input text is visible
              },
            }}
          />
          {props.mode === "edit" && (
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"} // Toggle between password and text
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "text.primary",
                },
                "& .MuiInputBase-root": {
                  color: "text.primary",
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading
              ? "Loading..."
              : props.mode === "generate"
              ? "Generate Password"
              : "Save"}
          </Button>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default PasswordModal;
