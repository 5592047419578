import axios from "axios";

// Set the base URL depending on the environment
const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "api" // Production URL
      : "http://localhost:8081/api", // Local development base URL
});

// Interceptor to attach headers to each request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Optionally, handle response errors globally
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors (e.g., redirect to login)
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
